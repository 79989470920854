import {
    Table,
    Modal,
    Form,
    Input,
    Button,
    Tooltip,
    Popconfirm,
    Row,
    Col,
    InputNumber,
    Select,
  } from "antd";
  import { notification, Space } from 'antd';

  import React from "react";
 
  import {
    FormOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    AppstoreAddOutlined,
    AppstoreOutlined
  } from "@ant-design/icons";
  import { connect } from "react-redux";
  
  import {
    get,
    create,
    edit,
    remove,
    getExtensions,
    addPermissionToExtension,
    removePermissionToExtension
  } from "../../api/permissions";
  
  import {
    addNetToExtension,
    removeWalletFromExtension,
  } from "../../api/nets";
  import {
    getAllExtensions
  } from "../../api/extensions";
  const { Option } = Select;
  const { Search } = Input;

  const permissonsTypes = ['building', 'messages', 'push']
//   const global_nets = [
//     'eth','bsc', 'cosmos', 'secret', 'osmosis', 'juno', 'kava', 'sifchain', 'band', 'persistence'
// ]
const global_nets = [
  {
      "net": "bsc",
      "ticker": "bnb"
  },
  {
      "net": "eth",
      "ticker": "eth"
  },
  {
      "net": "cosmos",
      "ticker": "atom"
  },
  {
      "net": "akash",
      "ticker": "act"
  },
  {
      "net": "secret",
      "ticker": "scrt"
  },
  {
      "net": "band",
      "ticker": "band"
  },
  {
      "net": "osmosis",
      "ticker": "osmosis"
  },
  {
      "net": "persistence",
      "ticker": "persistence"
  },
  {
      "net": "juno",
      "ticker": "juno"
  },
  {
      "net": "sifchain",
      "ticker": "sifchain"
  },
  {
      "net": "comdex",
      "ticker": "comdex"
  },
  {
      "net": "chihuahua",
      "ticker": "chihuahua"
  },
  {
      "net": "cheqd",
      "ticker": "cheqd"
  },
  {
      "net": "cerberus",
      "ticker": "cerberus"
  },
  {
      "net": "bitsong",
      "ticker": "bitsong"
  },
  {
      "net": "axelar",
      "ticker": "axelar"
  },
  {
      "net": "mantle",
      "ticker": "mantle"
  },
  {
      "net": "crescent",
      "ticker": "crescent"
  },
  {
      "net": "emoney",
      "ticker": "emoney"
  },
  {
      "net": "evmos",
      "ticker": "evmos"
  },
  {
      "net": "fetch",
      "ticker": "fetch"
  },
  {
      "net": "gbridge",
      "ticker": "gbridge"
  },
  {
      "net": "injective",
      "ticker": "injective"
  },
  {
      "net": "iris",
      "ticker": "iris"
  },
  {
      "net": "kava",
      "ticker": "kava"
  },
  {
      "net": "kichain",
      "ticker": "kichain"
  },
  {
      "net": "konstellation",
      "ticker": "konstellation"
  },
  {
      "net": "lum",
      "ticker": "lum"
  },
  {
      "net": "orai",
      "ticker": "orai"
  },
  {
      "net": "regen",
      "ticker": "regen"
  },
  {
      "net": "rizon",
      "ticker": "rizon"
  },
  {
      "net": "sentinel",
      "ticker": "sentinel"
  },
  {
      "net": "stargaze",
      "ticker": "stargaze"
  },
  {
      "net": "starname",
      "ticker": "starname"
  },
  {
      "net": "umee",
      "ticker": "umee"
  },
  {
      "net": "dydx",
      "ticker": "dydx"
  },
  {
      "net": "coreum",
      "ticker": "coreum"
  },
  {
      "net": "lava",
      "ticker": "lava"
  },
  {
      "net": "nibiru",
      "ticker": "nibiru"
  },
  {
      "net": "nolus",
      "ticker": "nolus"
  },
  {
      "net": "quasar",
      "ticker": "quasar"
  },
  {
      "net": "saga",
      "ticker": "saga"
  },
  {
      "net": "sei",
      "ticker": "sei"
  },
  {
      "net": "teritori",
      "ticker": "teritori"
  },
  {
      "net": "provenance",
      "ticker": "hash"
  }
]
  class Wallet extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        dataSource: [],
        isEditing: false,
        AddPermissionToExtensionsShows: false,
        getPermissionToExtensionsShows: false,
        AddPermissionToExtensionseId: false,
        isAddingWallet: false,
        editableRow: {},
        editableextensionId: false,
        loading: false,
        pagination: {
          current: 1,
          pageSize: 10,
          total: 10,
          showSizeChanger: false,
        },
        extensionId: null,
        searchAddress: false,
        searchNet: false,
        searchExtensionName: false,
        uptimeSorter: false,
        feeSorter: false,
        nets: [],
        extensions: [],
        extensionsAdded: [],
      };
      this.formRef = React.createRef();
    
      this.columns = [
        {
          title: "id",
          dataIndex: "id",
          key: "id",
          width: 75,
          render: (id, record) => {
            return (
              <InputNumber
                defaultValue={id || -1}
                bordered={false}
              />
            );
          },
        },
        {
            type: "Type",
            dataIndex: "type",
            key: "type",
        },
        {
            title: () => {
                return (
                  <Search
                    value={this.state.searchNet || ""}
                    onChange={(event) => {
                      this.setState({
                        searchNet: event.target.value,
                      });
                    }}
                    bordered={false}
                    placeholder={"Parameters"}
                    allowClear
                    onSearch={(text) => {
                      this.onSearch("searchNet", text);
                    }}
                  />
                );
              },
           
            render: (_, record) => {
              return (
               <span>{JSON.stringify(record.parameters, null, 2)}</span>
              );
            },
        },
        {
            title: () => {
                return (
                  <Search
                    value={this.state.searchName || ""}
                    onChange={(event) => {
                      this.setState({
                        searchName: event.target.value,
                      });
                    }}
                    bordered={false}
                    placeholder={"Readable name"}
                    allowClear
                    onSearch={(text) => {
                      this.onSearch("searchName", text);
                    }}
                  />
                );
              },
            render: (_, record) => {
                return (
                 <span>{record.readable_name}</span>
                );
              },
        },
        {
          title: "Actions",
          render: (_, record) => {
            return (
              <Row>
                <Col span={12}>
                  <Popconfirm
                    title="Are you sure?"
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                    onConfirm={() => {
                      this.onRemoveExtensionWallet(record.id);
                    }}
                  >
                    <Tooltip title="Remove">
                      <Button
                        size="small"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        // style={{ marginLeft: 15 }}
                      />
                    </Tooltip>
                  </Popconfirm>
                </Col>
                <Col span={12}>
                  <Tooltip title="Edit">
                    <Button
                      size="small"
                      onClick={() => {
                        this.editRow(record);
                      }}
                      shape="circle"
                      primary
                      icon={<FormOutlined />}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Add extensions">
                    <Button
                      size="small"
                      onClick={() => {
                        this.addPermissionToExtensions(record);
                      }}
                      shape="circle"
                      primary
                      icon={<AppstoreAddOutlined />}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="List of extensions">
                    <Button
                      size="small"
                      onClick={() => {
                        this.getListPermissionOfExtensions(record);
                      }}
                      shape="circle"
                      primary
                      icon={<AppstoreOutlined />}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );
          },
        },
  
      ];
    }
  
  
  
    async onSearch(type, text) {
      const { pagination } = this.state;
      await this.setState({ [type]: text || false });
  
      await this.setState({ pagination: { ...pagination, current: 1 } });
  
      await this.loadWallets(this.state.pagination, this.props.net);
    }
  
    async componentDidMount() {
      await this.loadWallets();
  
    }
    async componentWillReceiveProps(props) {
      await this.setState({
        searchAddress: false,
        searchExtensionName: false,
        pagination: { ...this.state.pagination, current: 1 },
      });
  
      await this.loadWallets(this.state.pagination, props.net);
    }
  

    async onRemoveExtensionWallet(id){
      await remove(id)
      await this.loadWallets();
  
    }

    async addNetToExtensions(e, extensionId){
       console.log(e)
        const [net, ticker] = e.split('_');
        await addNetToExtension(extensionId, {net, ticker})
        await this.loadextensions();
    }
    async openAddWallet() {
       await this.setState({isAddingWallet: true})
     
    }
    async loadWallets(
      pagination = this.state.pagination,
      net = this.props.net,
      sorter
    ) {
      this.setState({ loading: true });
      let sorting = false;
      if (sorter) {
        if (sorter.column) {
          sorting = [sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"];
        }
      }
      const result = await get(
        (pagination.current - 1) * pagination.pageSize,
        pagination.pageSize,
        {
          net,
          readable_name: this.state.searchName,
          extensionName: this.state.searchExtensionName,
          searchNet: this.state.searchNet,
          sorting,
        }
      );
      if (result.success) {
        const extensions = [];
        for (let i = 0; i < result.data.rows.length; i++) {
          extensions.push({
            ...result.data.rows[i],
            key: result.data.rows[i].id.toString(),
          });
        }
        let currentPagination = {
          ...this.state.pagination,
          total: result.data.count,
        };
        if (pagination) {
          currentPagination = {
            ...pagination,
            total: result.data.count,
          };
        }
        console.log(extensions)
        this.setState({
          dataSource: extensions,
          pagination: currentPagination,
          total: result.data.count,
          loading: false,
        });
      }
    }
  
    async handleTableChange(pagination, filters, sorter) {
      await this.loadWallets(pagination, this.props.net, sorter);
    }
    openNotificationWithIcon = (type, 
      message = 'Permission doesn`t have extension list!',
      description = 'Firstly, add extension to the permission!') => {
        notification[type]({
          message,
          description
        });
    };
  
    render() {
      const { dataSource, pagination, loading } = this.state;
      return [
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={() =>this.openAddWallet()} type="primary" style={{width: '200px', margin: '20px'}}>Add permission</Button>
          </div>,
        <Table
            dataSource={dataSource}
            columns={this.columns}
            rowKey="id"
            pagination={pagination}
            loading={loading}
            onChange={(pagination, filters, sorter) => {
            this.handleTableChange(pagination, filters, sorter);
            }}
        />,
        this.AddWalletsModal(),
        this.EditingModal(),
        this.AddPermissionToExtensions(),
        this.GetPermissionToExtensions()
      ];
    }
  
    addWallet = async (id) => {
      await this.setState({ isAddingWallet: true, currentExtensionId: id });
    };
  
    addWalletHundle = async (values) => {
      console.log(values)
      const jsonTemplate = {
        parameters : {
          method: values.method,
          address: values.address,
          net: values.net
        },
        readable_name: values.readable_name,
        type: values.type
      }
      let result = await create(jsonTemplate)
      if(!result.success) this.openNotificationWithIcon('error', 'Permission already exist!', 'Try different options')
      await this.loadWallets();
    };

    resetFormEdit = () => {
      this.formRef.current.resetFields();
    };
    onChangeExtension = (e) => {
        this.setState({extensionId: e})
    }


    oneditExtension = async (values) => {
        const jsonTemplate = {
          parameters : {
            method: values.method,
            address: values.address,
            net: values.net
          },
          readable_name: values.readable_name,
          type: values.type
        };
        await edit(this.state.editablePermissonId, jsonTemplate)
        await this.setState({ isEditing: false });
        await this.loadWallets();
    }

    editRow = async (values) => {
      const jsonTemplate = {
        method: values.parameters.method,
        address: values.parameters.address,
        net: values.parameters.net,
        readable_name: values.readable_name,
        type: values.type
      }
      await this.setState({
        editableRow: values,
        editablePermissonId: values.id,
      });
      await this.setState({ isEditing: true });
      this.formRef.current.setFieldsValue(jsonTemplate);

    };
    onAddPermissionToExtensions = async (values) => {
      console.log(values)
      const result = await addPermissionToExtension({extensionId: parseInt(values.extensions), permissionId: parseInt(this.state.editablePermissonId)})

      if(!result.success) this.openNotificationWithIcon('error', 'Permission already added to extension!', 'Try different options')
      else
      await this.setState({ AddPermissionToExtensionsShows: false});
    }
    addPermissionToExtensions = async (values) => {
      await this.setState({
        editablePermissonId: values.id,
      });
      const extensions = await getAllExtensions()
      await this.setState({extensions})
      await this.setState({ AddPermissionToExtensionsShows: true});
    };
  
    getListPermissionOfExtensions = async (values) =>{
      const list = await getExtensions(values.id)
      if(list.length)
        await this.setState({
          extensionsAdded: list,
          getPermissionToExtensionsShows: true
        })
      else{
        this.openNotificationWithIcon('warning')
      }
    }
    deletePermissionFromExtension = async (id) => {
      await removePermissionToExtension(id)
      document.getElementById(`id-${id}`).remove();
      // await this.setState({
      //   getPermissionToExtensionsShows: false
      // })
    }
     AddWalletsModal() {
      return (
        <Modal
          title="Add new permissons"
          centered
          visible={this.state.isAddingWallet}
          onCancel={() => {
            this.setState({
              isAddingWallet: false,
            });
            this.resetFormEdit()
          }}
          okText="Save"
          cancelText="Cancel"
          okButtonProps={{
            form: "add-wallet",
            key: "submit",
            htmlType: "submit",
          }}
          width={700}
        >
          <Form
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 23 }}
            name="add-wallet"
            onFinish={(values) => {
              this.addWalletHundle(values);
              this.setState({
                isAddingWallet: false,
              });
            }}
            ref={this.formRef}
          >
            <Form.Item name={"readable_name"} label="Readable name" rules={[{ required: true }]}>
              <Input
                suffix={
                  <Tooltip title="Text / Readable name of permission">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name={"method"} label="Method" rules={[{ required: true }]}>
              <Input
                suffix={
                  <Tooltip title="Text / Method">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name={"address"} label="Address" rules={[{ required: false }]}>
              <Input
                suffix={
                  <Tooltip title="Text / Address">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name={"net"} label="Net" rules={[{ required: false }]}>
                <Select
                    showSearch
                    allowClear
                    onChange={this.onChangeExtension}
                    style={{ width: 400 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {global_nets.map((item, i) => 
                    <Option key={i} value={item.net}>{item.net}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item name={"type"} label="Type" rules={[{ required: true }]}>
                <Select
                    showSearch
                    onChange={this.onChangeExtension}
                    style={{ width: 400 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {permissonsTypes.map((item, i) => 
                    <Option key={i} value={item}>{item}</Option>)}
                </Select>
            </Form.Item>
          </Form>
        </Modal>
      );
    }

  EditingModal() {
    console.log(this.state.editLogo)
    return (
      <Modal
        title="Edit permision"
        centered
        visible={this.state.isEditing}
        onCancel={() => {
          this.setState({
            isEditing: false,
            editableRow: {},
            editableextensionId: false,
          });
        }}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{
          form: "edit-extension",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          name="edit-extension"
          onFinish={(values) => {
            this.oneditExtension(values);
          }}
          ref={this.formRef}
        >
          <Form.Item name={"readable_name"} label="Readable name" rules={[{ required: true }]}>
              <Input
                suffix={
                  <Tooltip title="Text / Readable name of permission">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name={"method"} label="Method" rules={[{ required: true }]}>
              <Input
                suffix={
                  <Tooltip title="Text / Method">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name={"address"} label="Address" rules={[{ required: false }]}>
              <Input
                suffix={
                  <Tooltip title="Text / Address">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name={"net"} label="Net" rules={[{ required: false }]}>
                <Select
                    showSearch
                    allowClear
                    onChange={this.onChangeExtension}
                    style={{ width: 400 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {global_nets.map((item, i) => 
                    <Option key={i} value={item.net}>{item.net}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item name={"type"} label="Type" rules={[{ required: true }]}>
                <Select
                    showSearch
                    onChange={this.onChangeExtension}
                    style={{ width: 400 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {permissonsTypes.map((item, i) => 
                    <Option key={i} value={item}>{item}</Option>)}
                </Select>
            </Form.Item>
        </Form>
      </Modal>
    );
  }
  

  AddPermissionToExtensions() {
    console.log(this.state.editLogo)
    return (
      <Modal
        title="Add permision to extension"
        centered
        visible={this.state.AddPermissionToExtensionsShows}
        onCancel={() => {
          this.setState({
            AddPermissionToExtensionsShows: false,
          });
        }}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{
          form: "edit-extension",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          name="edit-extension"
          onFinish={(values) => {
            this.onAddPermissionToExtensions(values);
          }}
          ref={this.formRef}
        >
           <Form.Item name={"extensions"} label="Extension" rules={[{ required: true }]}>
                <Select
                    showSearch
                    onChange={this.onChangeExtension}
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {this.state.extensions &&  this.state.extensions.length ? this.state.extensions?.map((item, i) => 
                    <Option key={i} value={item.id}>{item.name}</Option>) : []}
                </Select>
            </Form.Item>
        </Form>
      </Modal>
    );
  }
  
  GetPermissionToExtensions() {
    console.log(this.state.editLogo)
    return (
      <Modal
        title="List of extension"
        centered
        visible={this.state.getPermissionToExtensionsShows}
        onCancel={() => {
          this.setState({
            getPermissionToExtensionsShows: false,
          });
        }}
        footer={null}
        // // okText="Save"
        // // cancelText="Cancel"
        // okButtonProps={{
        //   form: "edit-extension",
        //   key: "submit",
        //   htmlType: "submit",
        // }}
      >
        {this.state.extensionsAdded &&  this.state.extensionsAdded.length ? this.state.extensionsAdded?.map((item, i) => 
        <div key={item.id} id={`id-${item.permsid}`}> {i+1}) {item.name} <DeleteOutlined onClick={()=>this.deletePermissionFromExtension(item.permsid)}/></div>) : []}
      </Modal>
    );
  }
}

  
  function mapStateToProps(state) {
    return {
      user: state.user,
    };
  }
  
  export default connect(mapStateToProps)(Wallet);
  