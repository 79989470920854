import axios from "axios";

import config from "./config";

export async function get(offset, limit, params) {
  const result = (
    await axios.post(
      `${config.apiEndpoint}/extensions/get?offset=${offset}&limit=${limit}`,
      params
    )
  ).data;
  return result;
}

export async function getAllExtensions() {
  const result = (
    await axios.get(
      `${config.apiEndpoint}/extensions/getAll`
    )
  ).data.data;
  return result;
}

export async function remove(id) {
  const result = (
    await axios.post(`${config.apiEndpoint}/extensions/remove`, { id })
  ).data;
  return result;
}

export async function edit(id, extension) {
  const data = new FormData();
  for(let i in extension){
    data.append(i, extension[i])
  }
  const result = (
    await axios.post(`${config.apiEndpoint}/extensions/edit/${id}`, data)
  ).data;
  return result;
}

export async function create(extension) {
  const data = new FormData();
  for(let i in extension){
    data.append(i, extension[i])
  }
  const result = (
    await axios.post(`${config.apiEndpoint}/extensions/new`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  ).data;
  return result;
}
export async function changeProd(id, value) {
 
  const result = (
    await axios.post(`${config.apiEndpoint}/extensions/is_prod`, {
      id, value
    })
  ).data;
  return result;
}
export async function changeDev(id, value) {
 
  const result = (
    await axios.post(`${config.apiEndpoint}/extensions/is_dev`, {
      id, value
    })
  ).data;
  return result;
}
export async function removeLogo(id) {
 
  const result = (
    await axios.delete(`${config.apiEndpoint}/extensions/remove/logo/${id}`)
  ).data;
  return result;
}
export async function removeBg(id, value) {
 
  const result = (
    await axios.delete(`${config.apiEndpoint}/extensions/remove/bg/${id}`)
  ).data;
  return result;
}

export async function getWallets(offset, limit, params) {
  const result = (
    await axios.post(
      `${config.apiEndpoint}/extensions/wallets?offset=${offset}&limit=${limit}`,
      params
    )
  ).data;
  return result;
}
