import akashLogo from "./assets/networks-images/akash.svg";
import bandLogo from "./assets/networks-images/band.svg";
import cosmosLogo from "./assets/networks-images/cosmos.svg";
import iconLogo from "./assets/networks-images/icon.svg";
import iostLogo from "./assets/networks-images/iost.svg";
import kavaLogo from "./assets/networks-images/kava.svg";
import polkadotLogo from "./assets/networks-images/polkadot.svg";
import secretLogo from "./assets/networks-images/secret.svg";
import tezosLogo from "./assets/networks-images/tez.svg";
import unknownLogo from "./assets/networks-images/unknown.svg";

const networks = {
  akash: {
    logo: akashLogo,
  },
  band: {
    logo: bandLogo,
  },
  cosmos: {
    logo: cosmosLogo,
  },
  icon: {
    logo: iconLogo,
  },
  iost: {
    logo: iostLogo,
  },
  kava: {
    logo: kavaLogo,
  },
  polkadot: {
    logo: polkadotLogo,
  },
  secret: {
    logo: secretLogo,
  },
  tezos: {
    logo: tezosLogo,
  },
};

export default networks;


export const unknownNetwork = {
    logo: unknownLogo,
}