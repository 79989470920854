import * as types from "../actions/actionTypes";

import networks from "./../../networks"

function userReducer(
  state = {
    auth: false,
    token: false,
    selectedNet: "akash",
    networks: Object.keys(networks)
  },
  action = null
) {
  switch (action.type) {
    case types.CHANGE_USER: {
      return Object.assign({}, state, action.data);
    }
    default:
      return state;
  }
}

export default userReducer;
