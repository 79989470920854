import { combineReducers } from "redux";
import modalsReducer from "./modalsReducer";
import userReducer from "./userReducer";

const allReducers = combineReducers({
  modals: modalsReducer,
  user: userReducer
});

export default allReducers;
