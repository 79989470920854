import {
    Table,
    Modal,
    Form,
    Input,
    Button,
    Tooltip,
    Popconfirm,
    Row,
    Col,
    InputNumber,
    Select,
  } from "antd";
  
  import React from "react";
  
  import {
    DeleteOutlined,
    InfoCircleOutlined,
  } from "@ant-design/icons";
  import { connect } from "react-redux";
  
  import {
    getWallets,
    getAllExtensions
  } from "../../api/extensions";
  
  import {
    addNetToExtension,
    removeWalletFromExtension,
    addWalletFromExtension
  } from "../../api/nets";
  
  const { Option } = Select;
  const { Search } = Input;

  
  class Wallet extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        dataSource: [],
        isEditing: false,
        isAddingWallet: false,
        editableRow: {},
        editableextensionId: false,
        loading: false,
        pagination: {
          current: 1,
          pageSize: 10,
          total: 10,
          showSizeChanger: false,
        },
        extensionId: null,
        searchAddress: false,
        searchExtensionName: false,
        uptimeSorter: false,
        feeSorter: false,
        nets: [],
        extensions: []
      };
      this.formRef = React.createRef();
    
      this.columns = [
        {
          title: "id",
          dataIndex: "id",
          key: "id",
          width: 75,
          render: (id, record) => {
            return (
              <InputNumber
                defaultValue={id || -1}
                bordered={false}
              />
            );
          },
        },
        {
            title: "UserId",
            dataIndex: "userId",
            key: "userId",
        },
        {
            title: "Net",
            dataIndex: "net",
            key: "net",
        },
        {
            title: () => {
                return (
                  <Search
                    value={this.state.searchAddress || ""}
                    onChange={(event) => {
                      this.setState({
                        searchAddress: event.target.value,
                      });
                    }}
                    bordered={false}
                    placeholder={"Address"}
                    allowClear
                    onSearch={(text) => {
                      this.onSearch("searchAddress", text);
                    }}
                  />
                );
              },
            dataIndex: "address",
            key: "address",
        },
        {
            title: "ExtensionId",
            render: (_, record) => {
                return (
                 <span>{record.extension.id}</span>
                );
              },
        },
        {
            title: () => {
                return (
                  <Search
                    value={this.state.searchExtensionName || ""}
                    onChange={(event) => {
                      this.setState({
                        searchExtensionName: event.target.value,
                      });
                    }}
                    bordered={false}
                    placeholder={"ExtensionName"}
                    allowClear
                    onSearch={(text) => {
                      this.onSearch("searchExtensionName", text);
                    }}
                  />
                );
              },
            render: (_, record) => {
                return (
                 <span>{record.extension.name}</span>
                );
              },
        },
        {
          title: "Actions",
          render: (_, record) => {
            return (
              <Row>
                <Col span={7}>
                  <Popconfirm
                    title="Are you sure?"
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                    onConfirm={() => {
                      this.onRemoveExtensionWallet(record.id);
                    }}
                  >
                    <Tooltip title="Remove">
                      <Button
                        size="small"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        // style={{ marginLeft: 15 }}
                      />
                    </Tooltip>
                  </Popconfirm>
                </Col>
                <Col span={7}>
                 
                </Col>
              </Row>
            );
          },
        },
  
      ];
    }
  
  
  
    async onSearch(type, text) {
      const { pagination } = this.state;
      await this.setState({ [type]: text || false });
  
      await this.setState({ pagination: { ...pagination, current: 1 } });
  
      await this.loadWallets(this.state.pagination, this.props.net);
    }
  
    async componentDidMount() {
      await this.loadWallets();
  
    }
    async componentWillReceiveProps(props) {
      await this.setState({
        searchAddress: false,
        searchExtensionName: false,
        pagination: { ...this.state.pagination, current: 1 },
      });
  
      await this.loadWallets(this.state.pagination, props.net);
    }
  

    async onRemoveExtensionWallet(id){
      await removeWalletFromExtension(id)
      await this.loadWallets();
  
    }

    async addNetToExtensions(e, extensionId){
       console.log(e)
        const [net, ticker] = e.split('_');
        await addNetToExtension(extensionId, {net, ticker})
        await this.loadextensions();
    }
    async openAddWallet() {
       await this.setState({isAddingWallet: true})
       const extensions = await getAllExtensions()
       await this.setState({extensions})
       console.log(this.state.extensions)
    }
    async loadWallets(
      pagination = this.state.pagination,
      net = this.props.net,
      sorter
    ) {
      this.setState({ loading: true });
      let sorting = false;
      if (sorter) {
        if (sorter.column) {
          sorting = [sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"];
        }
      }
      const result = await getWallets(
        (pagination.current - 1) * pagination.pageSize,
        pagination.pageSize,
        {
          net,
          address: this.state.searchAddress,
          extensionName: this.state.searchExtensionName,
          sorting,
        }
      );
      if (result.success) {
        const extensions = [];
        for (let i = 0; i < result.data.rows.length; i++) {
          extensions.push({
            ...result.data.rows[i],
            key: result.data.rows[i].id.toString(),
          });
        }
        let currentPagination = {
          ...this.state.pagination,
          total: result.data.count,
        };
        if (pagination) {
          currentPagination = {
            ...pagination,
            total: result.data.count,
          };
        }
  
        this.setState({
          dataSource: extensions,
          pagination: currentPagination,
          total: result.data.count,
          loading: false,
        });
      }
    }
  
    async handleTableChange(pagination, filters, sorter) {
      await this.loadWallets(pagination, this.props.net, sorter);
    }
  
    render() {
      const { dataSource, pagination, loading } = this.state;
      return [
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={() =>this.openAddWallet()} type="primary" style={{width: '200px', margin: '20px'}}>Add wallet</Button>
          </div>,
        <Table
            dataSource={dataSource}
            columns={this.columns}
            rowKey="id"
            pagination={pagination}
            loading={loading}
            onChange={(pagination, filters, sorter) => {
            this.handleTableChange(pagination, filters, sorter);
            }}
        />,
        this.AddWalletsModal()
      ];
    }
  
    addWallet = async (id) => {
      await this.setState({ isAddingWallet: true, currentExtensionId: id });
    };
  
    addWalletHundle = async (values) => {
      await addWalletFromExtension({...values, extensionId: this.state.extensionId})
      await this.loadWallets();
    };

    resetFormEdit = () => {
      this.formRef.current.resetFields();
    };
    onChangeExtension = (e) => {
        this.setState({extensionId: e})
    }

     AddWalletsModal() {
      return (
        <Modal
          title="Add wallet to extension"
          centered
          visible={this.state.isAddingWallet}
          onCancel={() => {
            this.setState({
              isAddingWallet: false,
            });
          }}
          okText="Save"
          cancelText="Cancel"
          okButtonProps={{
            form: "add-wallet",
            key: "submit",
            htmlType: "submit",
          }}
        >
          <Form
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            name="add-wallet"
            onFinish={(values) => {
              this.addWalletHundle(values);
              this.setState({
                isAddingWallet: false,
              });
            }}
            ref={this.formRef}
          >
            <Form.Item name={"net"} label="Net" rules={[{ required: true }]}>
              <Input
                suffix={
                  <Tooltip title="Text / Name of extensions">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name={"address"} label="Address" rules={[{ required: true }]}>
              <Input
                suffix={
                  <Tooltip title="Text / Short description">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name={"userId"} label="User id" rules={[{ required: true }]}>
              <InputNumber size="large" min={1} max={1000000000000000} defaultValue={0} />
            </Form.Item>
            <Form.Item name={"extensions"} label="Extension" rules={[{ required: true }]}>
                <Select
                    showSearch
                    onChange={this.onChangeExtension}
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {this.state.extensions &&  this.state.extensions.length ? this.state.extensions?.map((item, i) => 
                    <Option key={i} value={item.id}>{item.name}</Option>) : []}
                </Select>
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
  
  function mapStateToProps(state) {
    return {
      user: state.user,
    };
  }
  
  export default connect(mapStateToProps)(Wallet);
  