import * as types from "../actions/actionTypes";

function modalsReducer(
  state = {
    AddValidator: false,
    AddValidatorNet: "",
  },
  action = null
) {
  switch (action.type) {
    case types.CHANGE_MODAL: {
      return Object.assign({}, state, action.data);
    }
    default:
      return state;
  }
}

export default modalsReducer;
