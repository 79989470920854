import React from "react";
import { Menu, Typography} from "antd";
import {
  LogoutOutlined,
  TagOutlined,

} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";




const { Title } = Typography;

class SideBar extends React.Component {
  async componentDidMount() {
  }
  logout() {
    localStorage.setItem("token", "");
    window.location = "/login";
  }
  render() {
    const networksList = this.props.user.networks;
    return (
      <Menu
        theme="dark"
        mode="inline"
        style={{ height: "100%", width: 210 }}
        defaultOpenKeys={["sub1"]}
        forceSubMenuRender={true}
        defaultOpenKeys={["102"]}
        defaultSelectedKeys={["0"]}
      >
        <div className="centered" style={{ marginTop: 20, marginBottom: 20 }}>
          <Title level={4} style={{ color: "white" }}>
            Citadel.one
          </Title>
        </div>
      
        <Menu.Item key="102">
          <Link to={`/extensions`}>
            <TagOutlined style={{ marginRight: 8 }} />
            Extensions
          </Link>
        </Menu.Item>
        <Menu.Item key="105">
          <Link to={`/wallets`}>
            <TagOutlined style={{ marginRight: 8 }} />
            Wallets
          </Link>
        </Menu.Item>
        <Menu.Item key="106">
          <Link to={`/permissions`}>
            <TagOutlined style={{ marginRight: 8 }} />
            Permissions
          </Link>
        </Menu.Item>
        <Menu.Item
          key="107"
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 1,
            transition: "all 0.2s",
          }}
        >
          <Link
            to="/logout"
            onClick={() => {
              this.logout();
            }}
          >
            <LogoutOutlined style={{ marginRight: 5 }} /> Log Out
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(SideBar);
