import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Redirect } from 'react-router'
import { Layout } from "antd";

import SideBar from "../components/SideBar";
import ExtensionTable from "../components/Extension/ExtensionTable";
import Permissons from "../components/Permissions";
import Tags from "../components/Tags";
import AddExtnesion from "../components/Modals/AddExtnesion";
import Wallet from "../components/Wallet";
const { Sider } = Layout;

class AuthRouter extends React.Component {
  render() {
    return (
      <Router>
        <Layout style={{ height: "100%" }}>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0, 
            }}
            width={210}
          >
            <SideBar />
          </Sider>
          <Layout style={{ height: "100%", marginLeft: 210 }}>
            <Route path="/" render={() => (
              <Redirect to="/extensions" />
            )}/>
            <Route path="/extensions" component={ExtensionTable} />
            <Route path="/tags/" component={Tags} />
            <Route path="/wallets/" component={Wallet} />
            <Route path="/permissions/" component={Permissons} />
          </Layout>
        </Layout>
        <AddExtnesion />
      </Router>
    );
  }
}

export default AuthRouter;
