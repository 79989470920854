import axios from "axios";

import config from "./config";

export async function get(offset, limit, params) {
  const result = (
    await axios.post(
      `${config.apiEndpoint}/permissions/get?offset=${offset}&limit=${limit}`,
      params
    )
  ).data;
  return result;
}

export async function getExtensions(id) {
  const result = (
    await axios.get(
      `${config.apiEndpoint}/permissions/getExtensions/`+id
    )
  ).data.data;
  return result;
}
export async function getPermissionOfExtension(id) {
  const result = (
    await axios.get(
      `${config.apiEndpoint}/permissions/getPermissionOfExtension/`+id
    )
  ).data.data;
  return result;
}

export async function addPermissionToExtension(data) {
  const result = (
    await axios.post(
      `${config.apiEndpoint}/permissions/addPermissionToExtension`, data
    )
  ).data;
  return result;
}

export async function removePermissionToExtension(id) {
  const result = (
    await axios.delete(
      `${config.apiEndpoint}/permissions/removePermissionToExtension/`+id
    )
  ).data.data;
  return result;
}

export async function remove(id) {
  const result = (
    await axios.delete(`${config.apiEndpoint}/permissions/delete/`+ id)
  ).data;
  return result;
}

export async function edit(id, data) {
  
  const result = (
    await axios.put(`${config.apiEndpoint}/permissions/edit/${id}`, data)
  ).data;
  return result;
}

export async function create(data) {
 
  const result = (
    await axios.post(`${config.apiEndpoint}/permissions/add`, data)
  ).data;
  return result;
}
export async function changeProd(id, value) {
 
  const result = (
    await axios.post(`${config.apiEndpoint}/extensions/is_prod`, {
      id, value
    })
  ).data;
  return result;
}
export async function changeDev(id, value) {
 
  const result = (
    await axios.post(`${config.apiEndpoint}/extensions/is_dev`, {
      id, value
    })
  ).data;
  return result;
}
export async function removeLogo(id) {
 
  const result = (
    await axios.delete(`${config.apiEndpoint}/extensions/remove/logo/${id}`)
  ).data;
  return result;
}
export async function removeBg(id, value) {
 
  const result = (
    await axios.delete(`${config.apiEndpoint}/extensions/remove/bg/${id}`)
  ).data;
  return result;
}

export async function getWallets(offset, limit, params) {
  const result = (
    await axios.post(
      `${config.apiEndpoint}/extensions/wallets?offset=${offset}&limit=${limit}`,
      params
    )
  ).data;
  return result;
}
