import React from "react";
import { connect } from "react-redux";
import { Form, Input, message, Modal, Tooltip, Upload, Checkbox } from "antd";
import { PercentageOutlined, InfoCircleOutlined, InboxOutlined } from "@ant-design/icons";
import { ChromePicker } from 'react-color'

import { store } from "../../store/store";
import { changeModal } from "../../store/actions/actionsModals";
import { changeUserData } from "../../store/actions/actionsUser";

import { create as createValidator, getNetworks } from "../../api/extensions";

const { TextArea } = Input;
const { Dragger } = Upload;

const props = {
  name: 'file',
  multiple: false,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      console.log(info.file.originFileObj)
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};

class AddExtnesion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formRef: React.createRef(),
      pickerValue : {},
      color:'',
      is_prod: false,
      is_dev: false
    }
  }
 async onAddValidator(exntesion) {
    console.log(exntesion)
    const result = await createValidator({
      ...exntesion, 
      is_prod: this.state.is_prod,
      is_dev: this.state.is_dev,
      logo: exntesion.logo.file, 
      background: exntesion.background?.file || '',
      background_color: exntesion.background_color?.hex || '#fff'
    });
    if (result.success) {
      message.success("Success");
      await store.dispatch(changeModal({ AddValidator: false }));
      window.location.reload()
    } else message.error("Something went wrong")
  }

  componentWillReceiveProps(props) {
    this.setState((state) => {
      if (state.formRef.current) {
        state.formRef.current.setFieldsValue({net: props.modals.AddValidatorNet}); 
      }
      return state;
    })
  }
   changePicker =(e) =>{
     this.setState({ color: e.hex });
  }
   changeProd =(e) =>{
     console.log(e.target.checked);
     this.setState({ is_prod: e.target.checked });
  }
   changeDev =(e) =>{
     console.log(e.target.checked);
     this.setState({ is_dev: e.target.checked });
  }

  
  render() {
    const { modals } = this.props;
    return (
      <Modal
        title="Add extension"
        centered
        visible={modals.AddValidator}
        width={860}
        onCancel={async () => {
          await store.dispatch(changeModal({ AddValidator: false }));
        }}
        okText="Create"
        cancelText="Cancel"
        okButtonProps={{
          form: "add-validator",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          name="add-validator"
          onFinish={(values) => {
            this.onAddValidator(values);
          }}
          ref={this.state.formRef}
          initialValues={{net: modals.AddValidatorNet}}
        >
          <Form.Item name={"name"} label="Name" rules={[{ required: true }]}>
            <Input
              
              suffix={
                <Tooltip title="Text / Name of extensions">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"short_description"} label="Short description" rules={[{ required: true }]}>
            <Input
              suffix={
                <Tooltip title="Text / Short description">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"card_description"} label="Card description">
            <Input
              suffix={
                <Tooltip title="Text / Card description">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"description"} label="Description"rules={[{ required: true }]}>
              <TextArea allowClear style={{ height: 120 }}/>
          </Form.Item>
          <Form.Item name={"logo"} label="Logo" rules={[{ required: true }]}>
          <Dragger beforeUpload={() => false } {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
          </Form.Item>
          <Form.Item name={"background"} label="Background">
          <Dragger  beforeUpload={() => false} {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
          </Form.Item>
          <Form.Item name={"background_color"} label="Background  color" >
              <ChromePicker 
              color={this.state.color}
                value={this.state.pickerValue}
                onChange={this.changePicker}
              />
          </Form.Item>
          <Form.Item name={"url"} label="URL" rules={[{ required: true }]}>
            <Input
              
              suffix={
                <Tooltip title="Text / Url">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"url_prod"} label="URL PROD" rules={[{ required: true }]}>
            <Input
              suffix={
                <Tooltip title="Text / Url">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"url_video"} label="Video  url">
            <Input
              
              suffix={
                <Tooltip title="Text / Video url">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"is_prod"} label="Prodaction or develop?" >
            Prodaction: <Checkbox onChange={this.changeProd}/>
            Develop: <Checkbox onChange={this.changeDev}/>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    modals: state.modals,
    user: state.user
  };
}

export default connect(mapStateToProps)(AddExtnesion);
