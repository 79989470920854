import {
  Table,
  Tag,
  Modal,
  Form,
  Input,
  Button,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  message,
  Image,
  Popover,
  Badge,
  InputNumber,
  Select,
  Upload,
  Checkbox
  
} from "antd";
import { notification, Space } from 'antd';

import { store } from "./../../store/store";
import { changeModal } from "./../../store/actions/actionsModals";

import React from "react";

import {
  FormOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  InboxOutlined,
  AppstoreOutlined
} from "@ant-design/icons";
import { connect } from "react-redux";

import {
  get as getExtensions,
  edit as editExtension,
  remove as removeExtensions,
  changeProd,
  changeDev,
  removeBg,
  removeLogo
} from "../../api/extensions";
import {
  getPermissionOfExtension
} from "../../api/permissions"
import {
  addNetToExtension,
  removeNetFromExtension,
} from "../../api/nets";



import { unknownNetwork } from "../../networks";
import { ChromePicker } from 'react-color'
import AddExtnesion from "../Modals/AddExtnesion";

const { TextArea } = Input;
const { Option } = Select;
const { Search } = Input;
const { Dragger } = Upload;


const global_nets = [
  {
      "net": "bsc",
      "ticker": "bnb"
  },
  {
      "net": "btc",
      "ticker": "btc"
  },
  {
      "net": "polkadot",
      "ticker": "polkadot"
  },
  {
      "net": "tez",
      "ticker": "tez"
  },
  {
      "net": "eth",
      "ticker": "eth"
  },
  {
      "net": "cosmos",
      "ticker": "atom"
  },
  {
      "net": "akash",
      "ticker": "act"
  },
  {
      "net": "secret",
      "ticker": "scrt"
  },
  {
      "net": "band",
      "ticker": "band"
  },
  {
      "net": "osmosis",
      "ticker": "osmosis"
  },
  {
      "net": "persistence",
      "ticker": "persistence"
  },
  {
      "net": "juno",
      "ticker": "juno"
  },
  {
      "net": "sifchain",
      "ticker": "sifchain"
  },
  {
      "net": "comdex",
      "ticker": "comdex"
  },
  {
      "net": "chihuahua",
      "ticker": "chihuahua"
  },
  {
      "net": "cheqd",
      "ticker": "cheqd"
  },
  {
      "net": "cerberus",
      "ticker": "cerberus"
  },
  {
      "net": "bitsong",
      "ticker": "bitsong"
  },
  {
      "net": "axelar",
      "ticker": "axelar"
  },
  {
      "net": "mantle",
      "ticker": "mantle"
  },
  {
      "net": "crescent",
      "ticker": "crescent"
  },
  {
      "net": "emoney",
      "ticker": "emoney"
  },
  {
      "net": "evmos",
      "ticker": "evmos"
  },
  {
      "net": "fetch",
      "ticker": "fetch"
  },
  {
      "net": "gbridge",
      "ticker": "gbridge"
  },
  {
      "net": "injective",
      "ticker": "injective"
  },
  {
      "net": "iris",
      "ticker": "iris"
  },
  {
      "net": "jackal",
      "ticker": "jackal"
  },
  {
      "net": "kava",
      "ticker": "kava"
  },
  {
      "net": "kichain",
      "ticker": "kichain"
  },
  {
      "net": "konstellation",
      "ticker": "konstellation"
  },
  {
      "net": "kujira",
      "ticker": "kujira"
  },
  {
      "net": "lum",
      "ticker": "lum"
  },
  {
      "net": "omniflix",
      "ticker": "omniflix"
  },
  {
      "net": "orai",
      "ticker": "orai"
  },
  {
      "net": "regen",
      "ticker": "regen"
  },
  {
      "net": "rizon",
      "ticker": "rizon"
  },
  {
      "net": "sentinel",
      "ticker": "sentinel"
  },
  {
      "net": "stafi",
      "ticker": "stafi"
  },
  {
      "net": "stargaze",
      "ticker": "stargaze"
  },
  {
      "net": "stride",
      "ticker": "stride"
  },
  {
      "net": "starname",
      "ticker": "starname"
  },
  {
      "net": "arbitrum",
      "ticker": "arbitrum"
  },
  {
      "net": "avalanche",
      "ticker": "avalanche"
  },
  {
      "net": "optimism",
      "ticker": "optimism"
  },
  {
      "net": "polygon",
      "ticker": "polygon"
  },
  {
      "net": "umee",
      "ticker": "umee"
  },
  {
      "net": "archway",
      "ticker": "archway"
  },
  {
      "net": "quicksilver",
      "ticker": "quicksilver"
  },
  {
      "net": "celestia",
      "ticker": "celestia"
  },
  {
      "net": "dydx",
      "ticker": "dydx"
  },
  {
      "net": "coreum",
      "ticker": "coreum"
  },
  {
      "net": "lava",
      "ticker": "lava"
  },
  {
      "net": "nibiru",
      "ticker": "nibiru"
  },
  {
      "net": "nolus",
      "ticker": "nolus"
  },
  {
      "net": "quasar",
      "ticker": "quasar"
  },
  {
      "net": "saga",
      "ticker": "saga"
  },
  {
      "net": "sei",
      "ticker": "sei"
  },
  {
      "net": "teritori",
      "ticker": "teritori"
  },
  {
      "net": "provenance",
      "ticker": "hash"
  }
]

class ExtensionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      isEditing: false,
      editableRow: {},
      editableextensionId: false,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 10,
        showSizeChanger: false,
      },
      searchAddress: false,
      searchName: false,
      uptimeSorter: false,
      feeSorter: false,
      nets: [],
      currentExtensionId: 0,
      pickerValue : {},
      logo: null,
      bg: null,
      color:'',
      editLogo: null,
      editBg: null,
      editColor: '',
      permision: []
    };
    this.formRef = React.createRef();
  
    this.columns = [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
        width: 75,
        render: (id, record) => {
          return (
            <InputNumber
              defaultValue={id || -1}
              bordered={false}
            />
          );
        },
      },
      {
        title: "Image",
        dataIndex: "logo",
        key: "logo",
        render: (logo, record) => {
          return (
            <Image
              src={logo || unknownNetwork.logo}
              fallback={unknownNetwork.logo}
              className="table_validator-logo"
              alt={record.address}
            ></Image>
          );
        },
      },
      {
        title: "Image background",
        dataIndex: "background",
        key: "background",
        render: (background, record) => {
          return (
            <Image
              src={background || unknownNetwork.logo}
              fallback={unknownNetwork.logo}
              className="table_validator-logo"
              alt={record.address}
            ></Image>
          );
        },
      },
      {
        title: "Image background color",
        dataIndex: "background_color",
        key: "background_color",
        render: (background_color, record) => {
          return (
            <div style={{backgroundColor: background_color, width:"50px", height:"20px"}}>

            </div>
          );
        },
      },
      {
        title: () => {
          return (
            <Search
              value={this.state.searchName || ""}
              onChange={(event) => {
                this.setState({
                  searchName: event.target.value,
                });
              }}
              bordered={false}
              placeholder={"Name"}
              allowClear
              onSearch={(text) => {
                this.onSearch("searchName", text);
              }}
            />
          );
        },
        dataIndex: "name",
        key: "name",
        render: (text, record) => {
          if (record.customChanges) {
            if (record.description) {
              const content = (
                <div style={{ maxWidth: 300 }}>
                  <p>{record.description}</p>
                </div>
              );
              return (
                <Badge.Ribbon
                  text="Changed"
                  color="red"
                  style={{ marginTop: -15 }}
                >
                  <Popover
                    content={content}
                    title="Description"
                    placement="top"
                  >
                    <span
                      style={{
                        fontSize: 12,
                        color: "#108ee9",
                        cursor: "pointer",
                      }}
                    >
                      {text || "-"}
                    </span>
                  </Popover>
                </Badge.Ribbon>
              );
            } else {
              return (
                <Badge.Ribbon
                  text="Changed"
                  color="red"
                  style={{ marginTop: -15 }}
                >
                  <span style={{ fontSize: 12, cursor: "pointer" }}>
                    {text || "-"}
                  </span>
                </Badge.Ribbon>
              );
            }
          } else {
            const elements = [];
            if (record.description) {
              const content = (
                <div style={{ maxWidth: 300 }}>
                  <p>{record.description}</p>
                </div>
              );
              elements.push(
                <Popover
                  content={content}
                  title="Description"
                  placement="right"
                >
                  <span
                    style={{
                      fontSize: 12,
                      color: "#108ee9",
                      cursor: "pointer",
                    }}
                  >
                    {text || "-"}
                  </span>
                </Popover>
              );
            } else
              elements.push(
                <span style={{ fontSize: 12 }}>{text || "-"}</span>
              );

            return elements;
          }
        },
      },
      {
        title: "Nets",
        dataIndex: "extensions_nets",
        render: (nets, extension) => {
          return [
            nets.map((net) => {
              return (
                <Popconfirm
                  title="Remove net?"
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                  onConfirm={() => {
                    this.onRemoveExtensionNet(net.net, extension.id);
                  }}
                >
                  <Tooltip title={net.net}>
                    <Tag color={net.color} style={{ cursor: "pointer" }}>
                      {net.net}
                    </Tag>
                  </Tooltip>
                </Popconfirm>
              );
            }),

            <Popover
              content={
                <div style={{ width: 200 }}>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select a person"
                      optionFilterProp="children"
                      aria-autocomplete="false"
                      onChange={(e)=>{this.addNetToExtensions(e, extension.id)}}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        global_nets.map((i)=>{
                          return <Option value={i.net+'_'+i.ticker}>{i.net}</Option>
                        })
                    }
                    </Select>
                </div>
              }
              title="Add net"
              placement="top"
            >
              <PlusOutlined />
            </Popover>,
          ];
        },
      },
      {
        title: "Is production?",
        dataIndex: "is_prod",
        key: "is_prod",
        render: (is_prod, record) => {
          return (
            <Checkbox
            checked={is_prod || false}
            onChange={(e)=> this.changeProd(e, record.id)}
          />
          );
        },
      },
      {
        title: "Is dev?",
        dataIndex: "is_dev",
        key: "is_dev",
        render: (is_dev, record) => {
          return (
            <Checkbox
            checked={is_dev || false}
            onChange={(e)=> this.changeDev(e, record.id)}
          />
          );
        },
      },
      {
        title: "Actions",
        render: (_, record) => {
          return (
            <Row>
              <Col span={7}>
                <Tooltip title="Edit">
                  <Button
                    size="small"
                    onClick={() => {
                      this.editRow(record);
                    }}
                    shape="circle"
                    primary
                    icon={<FormOutlined />}
                  />
                </Tooltip>
              </Col>
              <Col span={7}>
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                  onConfirm={() => {
                    this.onRemoveExtension(record.id);
                  }}
                >
                  <Tooltip title="Remove">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      style={{ marginLeft: 15 }}
                    />
                  </Tooltip>
                </Popconfirm>
              </Col>
              <Col span={14}>
                <Tooltip title="Show permissions">
                    <Button
                      size="small"
                      onClick={() => {
                        this.addPermissionToExtensions(record);
                      }}
                      shape="circle"
                      primary
                      icon={<AppstoreOutlined />}
                    />
                  </Tooltip>
              </Col>
            </Row>
          );
        },
      },

    ];
  }

  openNotificationWithIcon = (type, 
    message = 'Extension doesn`t have extension list!',
    description = 'Firstly, add permission to the extension!') => {
      notification[type]({
        message,
        description
      });
  };

  async changeProd(e, id){
    // console.log({e, id});
      await changeProd(id, e.target.checked)
      await this.loadextensions();

  }
  async changeDev(e, id){
    // console.log({e, id});
      await changeDev(id, e.target.checked)
      await this.loadextensions();

  }

  async onSearch(type, text) {
    const { pagination } = this.state;
    await this.setState({ [type]: text || false });

    await this.setState({ pagination: { ...pagination, current: 1 } });

    await this.loadextensions(this.state.pagination, this.props.net);
  }

  async componentDidMount() {
    await this.loadextensions();

  }

  addPermissionToExtensions = async (values) => {
    await this.setState({
      editablePermissonId: values.id,
    });
    const permisions = await getPermissionOfExtension(values.id)
    
    if(!permisions.length) this.openNotificationWithIcon('warning')
    else{
      await this.setState({permisions})
      await this.setState({ AddPermissionToExtensionsShows: true});
    }
  };

  async componentWillReceiveProps(props) {
    await this.setState({
      searchAddress: false,
      searchName: false,
      pagination: { ...this.state.pagination, current: 1 },
    });

    await this.loadextensions(this.state.pagination, props.net);
  }

  async onRemoveExtension(extensionId){
    await removeExtensions(extensionId)
    await this.loadextensions();

  }
  async onRemoveExtensionNet(net, extensionId){
    await removeNetFromExtension(extensionId, net)
    await this.loadextensions();

  }
 
  changePicker =(e) =>{
    this.setState({ color: e.hex });
 }
  async addNetToExtensions(e, extensionId){
     console.log(e)
      const [net, ticker] = e.split('_');
      await addNetToExtension(extensionId, {net, ticker})
      await this.loadextensions();
  }
  async loadextensions(
    pagination = this.state.pagination,
    net = this.props.net,
    sorter
  ) {
    this.setState({ loading: true });
    let sorting = false;
    if (sorter) {
      if (sorter.column) {
        sorting = [sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"];
      }
    }
    const result = await getExtensions(
      (pagination.current - 1) * pagination.pageSize,
      pagination.pageSize,
      {
        net,
        address: this.state.searchAddress,
        name: this.state.searchName,
        sorting,
      }
    );
    if (result.success) {
      const extensions = [];
      for (let i = 0; i < result.data.rows.length; i++) {
        extensions.push({
          ...result.data.rows[i],
          key: result.data.rows[i].id.toString(),
        });
      }
      let currentPagination = {
        ...this.state.pagination,
        total: result.data.count,
      };
      if (pagination) {
        currentPagination = {
          ...pagination,
          total: result.data.count,
        };
      }

      this.setState({
        dataSource: extensions,
        pagination: currentPagination,
        total: result.data.count,
        loading: false,
      });
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.loadextensions(pagination, this.props.net, sorter);
  }

  render() {
    const { dataSource, pagination, loading } = this.state;
    return [
      <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={async () => {
            await store.dispatch(
              changeModal({ AddValidator: true, AddValidatorNet: "" })
            );
          }} type="primary" style={{width: '200px', margin: '20px'}}>Add extension</Button>
      </div>,
      <Table
        dataSource={dataSource}
        columns={this.columns}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={(pagination, filters, sorter) => {
          this.handleTableChange(pagination, filters, sorter);
        }}
      />,
      this.EditingModal(),
      this.AddPermissionToExtensions(),
      // <AddExtnesion />
    ];
  }

  editRow = async (record) => {
    await this.setState({
      editableRow: record,
      editableextensionId: record.id,
    });
    await this.setState({ isEditing: true, editLogo: record.logo, editBg: record.background, color: record.background_color });
    this.formRef.current.setFieldsValue(record);
  };

  removeBg = async () => {
    await removeBg(this.state.editableextensionId)
  }
  removeLogo = async () => {
    console.log(this.editableextensionId)
    await removeLogo(this.state.editableextensionId)
  }
  deletePermissionFromExtension = async (id) => {
    // await removePermissionToExtension(id)
    document.getElementById(`id-${id}`).remove();
    // await this.setState({
    //   getPermissionToExtensionsShows: false
    // })
  }
  resetFormEdit = () => {
    this.formRef.current.resetFields();
  };

  async oneditExtension(exntesion) {
    const ext = {
      ...exntesion,
      logo: this.state.logo, 
      background: this.state.bg,
      background_color: exntesion.background_color.hex || exntesion.background_color
    }
    console.log(this.state.bg, this.state.logo)
    if (!this.state.logo) delete ext.logo
    if(!this.state.bg) delete ext.background
    const result = await editExtension(
      this.state.editableextensionId,
      ext
    );

    if (result.success) {
      message.success(
        `extension updated (id: ${this.state.editableextensionId})`
      );
      await this.loadextensions();
      this.setState({ isEditing: false, logo: null, bg: null, editBg: null, editLogo: null});
      this.resetFormEdit()
    } else {
      message.error("Something went wrong");
    }
    
  }


   handleOnChangeLogo = ({file, fileList}) => {
     console.log(file)
   this.setState({
    editLogo : '',
    logo: file
   })
  }
   handleOnChangeBg = ({file, fileList}) => {
   this.setState({
    editBg: '',
    bg: file
   })
  };
  
  EditingModal() {
    console.log(this.state.editLogo)
    return (
      <Modal
        title="Edit extension"
        centered
        visible={this.state.isEditing}
        onCancel={() => {
          this.setState({
            isEditing: false,
            editableRow: {},
            editableextensionId: false,
          });
        }}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{
          form: "edit-extension",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          name="edit-extension"
          onFinish={(values) => {
            this.oneditExtension(values);
          }}
          ref={this.formRef}
        >
          <Form.Item name={"name"} label="Name" rules={[{ required: true }]}>
            <Input
              
              suffix={
                <Tooltip title="Text / Name of extensions">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"short_description"} label="Short description" rules={[{ required: true }]}>
            <Input
              suffix={
                <Tooltip title="Text / Short description">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"card_description"} label="Card description">
            <Input
              suffix={
                <Tooltip title="Text / Card description">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"description"} label="Description"rules={[{ required: true }]}>
              <TextArea allowClear style={{ height: 120 }}/>
          </Form.Item>
          <Form.Item name={"logo"} label="Logo"  >
          <Dragger beforeUpload={() => false} accept="image/*,.svg" maxCount={1} name="logo" onChange={this.handleOnChangeLogo}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
          {this.state.editLogo ? 
          <div>
            <img width={50} height={50} src={this.state.editLogo}/>
            <span style={{marginLeft: '5px', fontSize: '18px', cursor: 'pointer'}} onClick={this.removeLogo}>x</span>
          </div> : ''}
          </Form.Item>
          <Form.Item name={"background"} label="Background" >
          <Dragger beforeUpload={() => false} accept="image/*,.svg" maxCount={1} onChange={this.handleOnChangeBg} name="bg">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
          {this.state.editBg ? 
          <div>
            <img width={50} height={50} src={this.state.editBg}/>
            <span style={{marginLeft: '5px', fontSize: '18px', cursor: 'pointer'}} onClick={this.removeBg}>x</span>
          </div> : ''}
          </Form.Item>
          <Form.Item name={"background_color"} label="Background  color" rules={[{ required: true }]}>
            <ChromePicker 
                color={this.state.color}
                value={this.state.pickerValue}
                onChange={this.changePicker}
              />
          </Form.Item>
          <Form.Item name={"url"} label="URL" rules={[{ required: true }]}>
            <Input
              
              suffix={
                <Tooltip title="Text / Url">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"url_prod"} label="URL PROD" rules={[{ required: true }]}>
            <Input
              
              suffix={
                <Tooltip title="Text / Url">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name={"url_video"} label="Video  url">
            <Input
              
              suffix={
                <Tooltip title="Text / Video url">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
  AddPermissionToExtensions() {
    console.log(this.state.editLogo)
    return (
      <Modal
        title="List of permision"
        centered
        visible={this.state.AddPermissionToExtensionsShows}
        onCancel={() => {
          this.setState({
            AddPermissionToExtensionsShows: false,
          });
        }}
        width="1000px"
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{
          form: "edit-extension",
          key: "submit",
          htmlType: "submit",
        }}
        footer={null}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          name="edit-extension"
          onFinish={(values) => {
            this.onAddPermissionToExtensions(values);
          }}
          ref={this.formRef}
        >
                 {this.state.permisions &&  this.state.permisions.length ? this.state.permisions?.map((item, i) => 
          <div key={item.id} style={{height: "50px", border: "1px solid #cecece", display: "flex", justifyContent:"space-between"}}> <b>{i+1}){item.readable_name}</b>  <p>{JSON.stringify(item.parameters, null, 2)}</p>  <p>{item.type}</p></div>) : []} 
        </Form>
      </Modal>
    );
  }
  
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(ExtensionTable);
