import axios from "axios";

import config from "./config";


export async function addNetToExtension(extensionId, data) {
  const result = (
    await axios.put(`${config.apiEndpoint}/extensions/${data.net}/${extensionId}/add`,{
      ticker: data.ticker
    })
  ).data;
  return result;
}

export async function removeNetFromExtension(extensionId, net) {
  const result = (
    await axios.put(`${config.apiEndpoint}/extensions/${net}/${extensionId}/remove`)
  ).data;
  return result;
}

export async function addWalletFromExtension(data) {
  const result = (
    await axios.post(`${config.apiEndpoint}/extensions/wallets/add/`, data)
  ).data;
  return result;
}

export async function removeWalletFromExtension(id) {
  const result = (
    await axios.delete(`${config.apiEndpoint}/extensions/wallets/remove/`+id)
  ).data;
  return result;
}