import React from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Redirect } from 'react-router'
import axios from "axios";
import { login } from "./../api/user";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    }
  }
  async onLogin(data) {
    const result = await login(data.login, data.password);
    if (!result.success) {
      message.error("Access denied", 5);
      return;
    }
    message.success("Success");
    const token = result.token;
    localStorage.setItem("token", token);
    axios.defaults.headers.common["x-access-token"] = token;
    window.location = "/"
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to='/' />
    }
    return (
      <Row
        justify="center"
        align="middle"
        style={{ width: "100%", height: "100%", backgroundColor: "#fff" }}
      >
        <Col xs={16} sm={14} md={12} lg={10} xl={8} xxl={6}>
          <Form onFinish={(data) => this.onLogin(data)}>
            <Form.Item
              name="login"
              rules={[
                {
                  required: true,
                  message: "Please input login!",
                },
              ]}
            >
              <Input
                placeholder="Login"
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary" className="wh100">
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default Login;
