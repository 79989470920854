import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { verify } from "./../api/user";

import { store } from "./../store/store";

import { changeUserData } from "./../store/actions/actionsUser";

import AuthRouter from "./AuthRouter";
import UnAuthRouter from "./UnAuthRouter";

class WebPage extends React.Component {
  async componentDidMount() {
    const tokenLocalStorage = localStorage.getItem("token");
    const verifyResult = await verify(tokenLocalStorage);
    if (verifyResult.success) {
      axios.defaults.headers.common['x-access-token'] = tokenLocalStorage;
      await store.dispatch(changeUserData({auth: true, token: tokenLocalStorage}))
    }
  }
  render() {
    return this.props.user.auth ?  <AuthRouter /> : <UnAuthRouter />;
  }
}


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(WebPage);
