import axios from "axios";

import config from "./config";

export async function login(login, password) {
  const result = (await axios.post(`${config.apiEndpoint}/user/login`, { login, password })).data
  return result;
}

export async function verify(token) {
  const result = (await axios.post(`${config.apiEndpoint}/user/verify`, { token })).data
  return result;
}