import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./index.css";

import WebPage from "./containers/WebPage";

import { store } from "./store/store";

let app = document.getElementById("root");

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <WebPage />
      </Provider>
    );
  }
}

ReactDOM.render(<App />, app);
